@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200;6..12,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&family=Nunito+Sans:opsz,wght@6..12,200;6..12,400&display=swap');

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-Black.woff') format('woff2'),
    url('./assets/fonts/Gotham-Black.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/Gotham-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-BookItalic.woff2') format('woff2'),
    url('./assets/fonts/Gotham-BookItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}


@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-BookItalic.woff2') format('woff2'),
    url('./assets/fonts/Gotham-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-Book.woff2') format('woff2'),
    url('./assets/fonts/Gotham-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-LightItalic.woff2') format('woff2'),
    url('./assets/fonts/Gotham-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}



@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-Medium.woff2') format('woff2'),
    url('./assets/fonts/Gotham-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-Thin.woff2') format('woff2'),
    url('./assets/fonts/Gotham-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-Medium.woff2') format('woff2'),
    url('./assets/fonts/Gotham-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}




@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-UltraItalic.woff2') format('woff2'),
    url('./assets/fonts/Gotham-UltraItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}



* {
  font-family: "Gotham";
  font-family: 'Nunito Sans', sans-serif;
  font-family: 'Inter', sans-serif;
}


@media (max-width: 768px) {

  /* Adjust for mobile devices */
  #p5-project-detail {
    width: 90vw;
    /* 100% of the viewport width */
    height: 90vw;
    max-width: 100%;
    overflow: hidden;
  }

  #p5-project-detail canvas {
    transform: scale(0.6);
    /* Example: scales to 60% of original size */
    transform-origin: top left;
  }
}





html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Geometric";
  background-color: black !important;
  color: #fff;
  /* font-family: Raleway,Geometric,Segoe UI,Helvetica Neue,Arial,sans-serif; */
  font-family: "Gotham";
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a,
a:visited {
  color: "black";
  text-decoration: none;
}

a.active {
  text-decoration: underline;
}

a:hover {
  text-decoration: underline;
}

.mobile-link:hover {
  text-decoration: none;
}

* {
  color: white
}

button.crossmin-btn {
  font-family: 'Metropolis';
  font-size: '20px';
  font-weight: '400'
}

@font-face {
  font-family: 'metropolis';
  src: url('./assets/fonts/metropolis.semi-bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'nunito-sans';
  src: url('./assets/fonts/nunito-sans.regular.ttf') format('truetype');
  font-weight: 400;
}